import React from 'react';
import { useGLTF } from 'drei';
import modelConfig from '../../config/model.config';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Meshes = () => {
    const gltfModel = useGLTF(modelConfig.path.meshes, true);
    gltfModel.scene.castShadow = true;
    gltfModel.scene.receiveShadow = true;
    // gltfModel.scene.children.forEach(child => {
    //     child.castShadow = true;
    //     child.receiveShadow = true;
    // })
    
    gltfModel.scene.children.forEach(child => {
        if (child.type === "Object3D") {
            child.children.forEach(child => {
                child.castShadow = true;
                child.receiveShadow = true;
            })
        }
        child.castShadow = true;
        child.receiveShadow = true;
    })

    const webModel = <primitive object={gltfModel.scene} />

    return <>{webModel}</>;
}

export default Meshes;