import React, { useEffect, useRef } from 'react';


//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Lightning = () => {
    const lightRef = useRef<JSX.Element>() as any;
    useEffect(() => {
        if (lightRef.current !== undefined) {
            const d = 60;
            lightRef.current.shadow.mapSize.width = 2048;
            lightRef.current.shadow.mapSize.height = 2048;
            lightRef.current.shadow.camera.left = -d;
            lightRef.current.shadow.camera.right = d;
            lightRef.current.shadow.camera.top = d;
            lightRef.current.shadow.camera.bottom = -d;
            lightRef.current.shadow.camera.far = 1000;
            lightRef.current.shadow.bias = -0.0001;
        };
    });

    return (<>
        {/* <directionalLight castShadow={false} intensity={.8} position={[5, 5, 5]} /> */}
        {/* <pointLight castShadow={false} intensity={.5} position={[-100,80,110]} />
        <pointLight castShadow={true} intensity={.7} position={[-100,80,110]} />
        <ambientLight intensity={.7}/> */}
        <ambientLight intensity={.3} />
        <pointLight intensity={.2} position={[6, 3, -3]} castShadow={true} ref={lightRef} />
        <pointLight intensity={.2} position={[-1, 3, -3]} castShadow={true} ref={lightRef} />
        <pointLight intensity={.2} position={[-5, 3, -3]} castShadow={true}ref={lightRef} />
        <pointLight intensity={.2} position={[-5, 3, 2]} castShadow={true} ref={lightRef} />
        <pointLight intensity={.2} position={[3, 3, 2]} castShadow={true} ref={lightRef} />
    </>);
}

export default Lightning;